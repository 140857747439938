import {
  ADMIN_GET_BANQUET_INQUIRY,
  ADMIN_GET_FEEDBACK_INQUIRY,
  ADMIN_GET_FRANCHISE_INQUIRY,
} from '../constants/inquiryConstants'

const initialState = {
  banquetInquiry: [],
  feedbackInquiry: [],
  franchiseInquiry: [],
}

export default function inquiryReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_BANQUET_INQUIRY: {
      return {
        ...state,
        banquetInquiry: action.payload,
      }
    }
    case ADMIN_GET_FEEDBACK_INQUIRY: {
      return {
        ...state,
        feedbackInquiry: action.payload,
      }
    }
    case ADMIN_GET_FRANCHISE_INQUIRY: {
      return {
        ...state,
        franchiseInquiry: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
