import { createRoot } from 'react-dom/client'
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from 'react-redux'
import store from './store'

const root = document.getElementById('root')
const rootElement = createRoot(root)

rootElement.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

reportWebVitals()
