import {
  ADMIN_GET_OUTLET,
  ADMIN_ADD_OUTLET,
  ADMIN_UPDATE_OUTLET,
  ADMIN_DELETE_OUTLET,
} from '../constants/outletConstants'

export default function brandReducer(state = { outlets: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_OUTLET: {
      return {
        outlets: action.payload,
      }
    }

    case ADMIN_ADD_OUTLET: {
      let outlets = [...state.outlets]
      outlets.unshift(action.payload)
      return {
        ...state,
        outlets: outlets,
      }
    }

    case ADMIN_UPDATE_OUTLET: {
      let outlets = [...state.outlets]
      let index = outlets.findIndex((outlet) => outlet.id === action.payload.id)
      outlets[index] = action.payload
      return {
        ...state,
        brands: outlets,
      }
    }

    case ADMIN_DELETE_OUTLET: {
      let outlets = [...state.outlets]
      let index = outlets.findIndex((outlet) => outlet.id === action.payload.id)
      outlets.splice(index, 1)
      return { ...state, outlets: outlets }
    }
    default: {
      return state
    }
  }
}
