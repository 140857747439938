import {
  ADMIN_GET_BANNERS,
  ADMIN_ADD_BANNER,
  ADMIN_UPDATE_BANNER,
  ADMIN_DELETE_BANNER,
} from '../constants/bannerConstants'

export default function bannerReducer(state = { banners: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_BANNERS:
      return {
        banners: action.payload,
      }

    case ADMIN_ADD_BANNER: {
      let banners = [...state.banners]
      banners.unshift(action.payload)
      return {
        ...state,
        banners: banners,
      }
    }

    case ADMIN_UPDATE_BANNER: {
      let banners = [...state.banners]
      const index = banners.findIndex((banner) => banner.id === action.payload.id)
      banners[index] = action.payload
      return {
        banners: banners,
      }
    }

    case ADMIN_DELETE_BANNER: {
      let banners = [...state.banners]
      const index = banners.findIndex((banner) => banner.id === action.payload)
      banners.splice(index, 1)
      return {
        ...state,
        banners: banners,
      }
    }

    default:
      return state
  }
}
