import {
  ADMIN_GET_ROLES,
  ADMIN_GET_PERMISSIONS,
  ADMIN_ADD_ROLE,
  ADMIN_UPDATE_ROLE,
  ADMIN_DELETE_ROLE,
} from '../constants/roleConstants'

const initialState = {
  permissions: [],
  roles: [],
}
export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      }

    case ADMIN_ADD_ROLE: {
      let roles = [...state.roles]
      roles.unshift(action.payload)
      return {
        ...state,
        roles: roles,
      }
    }

    case ADMIN_UPDATE_ROLE: {
      let roles = [...state.roles]
      let index = roles.findIndex((role) => role.id === action.payload.id)
      roles[index] = action.payload
      return {
        ...state,
        roles: roles,
      }
    }

    case ADMIN_DELETE_ROLE: {
      let roles = [...state.roles]
      let index = roles.findIndex((role) => role.id === action.payload.id)
      roles.splice(index, 1)
      return { ...state, roles: roles }
    }

    case ADMIN_GET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
