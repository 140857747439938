import {
  ADMIN_ADD_MEDIA,
  ADMIN_DELETE_MEDIA,
  ADMIN_GET_MEDIA,
  ADMIN_UPDATE_MEDIA,
  ADMIN_UPDATE_MEDIA_PAGE,
} from '../constants/mediaConstants'

export default function mediaReducer(state = { medias: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_MEDIA: {
      return {
        medias: action.payload,
      }
    }

    case ADMIN_ADD_MEDIA: {
      let medias = [...state.medias]
      medias.unshift(action.payload)
      return {
        ...state,
        medias: medias,
      }
    }

    case ADMIN_UPDATE_MEDIA: {
      let medias = [...state.medias]
      let index = medias.findIndex((media) => media.id === action.payload.id)
      medias[index] = action.payload
      return {
        ...state,
        medias: medias,
      }
    }

    case ADMIN_DELETE_MEDIA: {
      let medias = [...state.medias]
      let index = medias.findIndex((media) => media.id === action.payload.id)
      medias.splice(index, 1)
      return { ...state, medias }
    }

    case ADMIN_UPDATE_MEDIA_PAGE: {
      let medias = [...state.medias]
      let index = medias.findIndex((media) => media.id === action.payload.id)
      medias[index] = action.payload
      return {
        ...state,
        medias: medias,
      }
    }

    default: {
      return state
    }
  }
}
