import {
  ADMIN_GET_TESTONOMIAL,
  ADMIN_ADD_TESTONOMIAL,
  ADMIN_UPDATE_TESTONOMIAL,
  ADMIN_DELETE_TESTONOMIAL,
} from '../constants/testonomialConstants'

export default function blogReducer(state = { testonomials: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_TESTONOMIAL: {
      return {
        testonomials: action.payload,
      }
    }

    case ADMIN_ADD_TESTONOMIAL: {
      let testonomials = [...state.testonomials]
      testonomials.unshift(action.payload)
      return {
        ...state,
        testonomials: testonomials,
      }
    }

    case ADMIN_UPDATE_TESTONOMIAL: {
      let testonomials = [...state.testonomials]
      let index = testonomials.findIndex((testonomial) => testonomial.id === action.payload.id)
      testonomials[index] = action.payload
      return {
        ...state,
        testonomials: testonomials,
      }
    }

    case ADMIN_DELETE_TESTONOMIAL: {
      let testonomials = [...state.testonomials]
      let index = testonomials.findIndex((testonomial) => testonomial.id === action.payload.id)
      testonomials.splice(index, 1)
      return { ...state, testonomials: testonomials }
    }
    default: {
      return state
    }
  }
}
