import { combineReducers } from 'redux'
import authReducer from './authReducer'
import sliderReducer from './sliderReducer'
import categoryReducer from './categoryReducer'
import subCategoryReducer from './subCategoryReducer'
import brandReducer from './brandReducer'
import productReducer from './productReducer'
import orderReducer from './orderReducer'
import settingsReducer from './settingsReducer'
import userReducer from './userReducer'
import dashboardReducer from './dashboardReducer'
import blogReducer from './blogReducer'
import serviceReducer from './serviceReducer'
import outletReducer from './outletReducer'
import bannerReducer from './bannerReducer'
import pageReducer from './pageReducer'
import testonomialReducer from './testonomialReducer'
import inquiryReducer from './inquiryReducer'
import roleReducer from './roleReducer'
import mediaReducer from './mediaReducer'
const appReducer = combineReducers({
  user: authReducer,
  sliderReducer,
  categoryReducer,
  subCategoryReducer,
  brandReducer,
  productReducer,
  orderReducer,
  settingsReducer,
  userReducer,
  dashboardReducer,
  blogReducer,
  serviceReducer,
  outletReducer,
  bannerReducer,
  pageReducer,
  testonomialReducer,
  inquiryReducer,
  roleReducer,
  mediaReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
