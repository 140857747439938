import {
  ADMIN_GET_SERVICE,
  ADMIN_ADD_SERVICE,
  ADMIN_UPDATE_SERVICE,
  ADMIN_DELETE_SERVICE,
} from '../constants/serviceConstants'

export default function blogReducer(state = { services: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_SERVICE: {
      return {
        services: action.payload,
      }
    }

    case ADMIN_ADD_SERVICE: {
      let services = [...state.services]
      services.unshift(action.payload)
      return {
        ...state,
        services: services,
      }
    }

    case ADMIN_UPDATE_SERVICE: {
      let services = [...state.services]
      let index = services.findIndex((service) => service.id === action.payload.id)
      services[index] = action.payload
      return {
        ...state,
        services: services,
      }
    }

    case ADMIN_DELETE_SERVICE: {
      let services = [...state.services]
      let index = services.findIndex((service) => service.id === action.payload.id)
      services.splice(index, 1)
      return { ...state, services: services }
    }
    default: {
      return state
    }
  }
}
