import {
  ADMIN_GET_PAGES,
  ADMIN_ADD_PAGE,
  ADMIN_UPDATE_PAGE,
  ADMIN_DELETE_PAGE,
} from '../constants/pageConstants'

export default function pageReducer(state = { pages: [] }, action) {
  switch (action.type) {
    case ADMIN_GET_PAGES: {
      return {
        pages: action.payload,
      }
    }

    case ADMIN_ADD_PAGE: {
      let pages = [...state.pages]
      pages.unshift(action.payload)
      return {
        ...state,
        pages: pages,
      }
    }

    case ADMIN_UPDATE_PAGE: {
      let pages = [...state.pages]
      let index = pages.findIndex((page) => page.id === action.payload.id)
      pages[index] = action.payload
      return {
        ...state,
        pages: pages,
      }
    }

    case ADMIN_DELETE_PAGE: {
      let pages = [...state.pages]
      let index = pages.findIndex((page) => page.id === action.payload.id)
      pages.splice(index, 1)
      return { ...state, brands: pages }
    }
    default: {
      return state
    }
  }
}
